import React, { useState } from 'react';
import './FileUpload.css';
import logo from './enel-logo.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import UploadButton from './UploadButton';

import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import { useLocation } from 'react-router-dom';



function RegularizareUpload() {
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [progressBarPercent, setProgressBarPercent] = useState(0)
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [buttonAction, setButtonAction] = useState("Analizare Adresa");
  const [jsonString, setJsonString] = useState("");
  const [jsonString2, setJsonString2] = useState("");

  const jsonDataStringContAndInv = location.state ? location.state.jsonDataContAndInv : null;

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleUploadRegularizare = async () => {
    try {

      if(uploadSuccess){

        navigate('/pasireg',{ state: { jsonData: jsonString, jsonData2: jsonString2, jsonDataContAndInv: jsonDataStringContAndInv, adresaName: files[0].name} });

      }else if (files.length === 1) {

        setShowLoader(true);
        setShowProgress(true);

        
        setStatusMessage("Upload Documente");
        await delay(2000);
        setProgressBarPercent(25);

        const formDataRegularizare = new FormData();
        formDataRegularizare.append('regularizare_file', files[0]);

        setStatusMessage("Analiza Adresa Regularizare");
        //const responseInvoice = await fetch('http://localhost:81/regularizare_ai_service_by_file', {
        const responseInvoice = await fetch('https://enelbackend6.azurewebsites.net/regularizare_ai_service_by_file', {
          method: 'POST',
          body: formDataRegularizare
        });

        if (responseInvoice.ok) {
          const jsonDataRegularizare = await responseInvoice.json();
          console.log("jsonData>>",jsonDataRegularizare);

          setProgressBarPercent(50);
          setStatusMessage("Extragere Obligatii");

          setProgressBarPercent(75);
          setStatusMessage("Generare Sumar");
          await delay(2000);


            setJsonString(JSON.stringify(jsonDataRegularizare));

            //const responseExtract = await fetch('http://localhost:81/extract_from_reg_letter', {
            const responseExtract = await fetch('https://enelbackend6.azurewebsites.net/extract_from_reg_letter', {
              method: 'POST',
              body: formDataRegularizare
            });

            if (responseExtract.ok) {
              const jsonDataRegularizare2 = await responseExtract.json();
              console.log("jsonData2>>",jsonDataRegularizare2);   
    
              setJsonString2(JSON.stringify(jsonDataRegularizare2));

            setStatusMessage('');
            setMessage('Sumarul a fost generat');

            setProgressBarPercent(100);

          setUploadSuccess(true);
          setButtonAction("Validare Sumar")
            }
        } else {
          setMessage('File Adresa Regularizare upload failed.');
        }

        setShowLoader(false)

      } else {
        setMessage('Please select 1 file.');
      }
    } catch (error) {
      setMessage('An error occurred while uploading file.');
      console.error(error);
    }
  };


  return (


    <div className="App">
      <header className="App-header">
       

{/*         <div className="logo-style">
          <img src={logo} className="App-logo" alt="logo" />
        </div> */}


        <div className="container">

<h1 className="title">Documente Necesare:</h1>
<div className="upload-area">
  <label htmlFor="file-input">Adresa Regularizare</label>
  <input
    type="file"
    id="file-input"
    
    accept=".jpg, .png, .pdf, .doc, .docx"
    onChange={handleFileChange}
  />
</div>

{files.length > 0 && (
  <div className="selected-files">
    <h2 className="title">Adresa regularizare a fost incarcata:</h2>
    <ul>
      {files.map((file, index) => (
        <li key={index}>{file.name}</li>
      ))}
    </ul>
  </div>
)}

{statusMessage && <p className="text-blue-600">{statusMessage}</p>}
{message && <p className="text-red-600">{message}</p>}
{showProgress && (
      <ProgressBar
        percent={progressBarPercent}
        filledBackground="linear-gradient(to right, #1565c0  ,green)"
      />
)}
<br></br>
<UploadButton text={buttonAction} onClick={handleUploadRegularizare} loading={showLoader} disabled={showLoader} />
</div>

       
      </header>
    

    </div>
  );
}

export default RegularizareUpload;
