import { useMsal } from '@azure/msal-react';
import './FileUpload.css';
import logo from './enel-logo.png';
import UploadButton from './UploadButton';
import { loginRequest, b2cPolicies } from './authConfig';

function LoginPage() {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    loginRequest.extraQueryParameters = {"ui_locales" : "ro"};
    instance.loginRedirect(loginRequest);
  };

  return (
    <>
    <div className="App">
      <header className="App-header">
       

{/*         <div className="logo-style">
          <img src={logo} className="App-logo" alt="logo" />
        </div> */}


        <div className="container">
        <h2 className="title">Momentan nu ești autentificat</h2>
        <UploadButton text="Autentificare / Cont nou" onClick={initializeSignIn}/>
</div>
</header>
</div>
    
    </>
  );
};

export default LoginPage;