// SuccessPage.js
import React, { useState } from 'react';

import logo from './enel-logo.png';
import './SuccessPage.css'; // Import the CSS file
import UploadButton from './UploadButton';
import './FileUpload.css';
import { ProgressBar } from "react-step-progress-bar";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate



function PasiRegularizare() {
    const location = useLocation();
    const jsonDataString = location.state ? location.state.jsonData : null;
    const jsonDataString2 = location.state ? location.state.jsonData2 : null;
    const [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = useState('');
    const jsonDataStringContAndInv = location.state ? location.state.jsonDataContAndInv : null;
    const [statusMessage, setStatusMessage] = useState('');
    const [showProgress, setShowProgress] = useState(false)
    const [progressBarPercent, setProgressBarPercent] = useState(0)
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [buttonAction, setButtonAction] = useState("Generare Raspuns");
    const [jsonRegRespString, setJsonRegRespString] = useState("");
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    console.log("Original JsonData for Cont and Inv", jsonDataStringContAndInv);


  // Parse the JSON data if available
    const jsonData = jsonDataString ? JSON.parse(jsonDataString) : null;
    const jsonData2 = jsonDataString2 ? JSON.parse(jsonDataString2) : null;
    console.log("jsonData pasi",jsonData);
    console.log("jsonData2",jsonData2);
 
    const jsonDataContAndInv = jsonDataStringContAndInv ? JSON.parse(jsonDataStringContAndInv) : null;
    
    const generateResponse = async () => {
        try {

        if(uploadSuccess){

            navigate('/respreg',{ state: { jsonDataRegResp: jsonRegRespString } });
        
        } else {
    
            setShowLoader(true);
            setShowProgress(true);

            setProgressBarPercent(25);
            setStatusMessage("Incarcare sumar");

            const jsonComplete = 
            {"invoices_and_contracts":jsonDataContAndInv,
            "court_request":jsonData2};

            setProgressBarPercent(25);
            setStatusMessage("Analiza context");

            //const responseReg = await fetch('http://localhost:81/create_reqularizare_response', {
            const responseReg = await fetch('https://enelbackend6.azurewebsites.net/create_reqularizare_response', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            //  body: jsonDataStringContAndInv
              body: JSON.stringify(jsonComplete)
            //  body: JSON.stringify({
            //    firstParam: 'yourValue',
            //    secondParam: 'yourOtherValue',
            //  })
            });
            
            setProgressBarPercent(50);
            setStatusMessage("Generare raspuns");
    
            if (responseReg.ok) {
              const jsonDataRegResp = await responseReg.json();
              console.log("jsonData>>",jsonDataRegResp);

              setProgressBarPercent(75);
              setStatusMessage("Incarcare raspuns");

            setJsonRegRespString(JSON.stringify(jsonDataRegResp));

            setStatusMessage('');
            setMessage('Raspunsul a fost generat');
            setProgressBarPercent(100);  
            setShowLoader(false)

            setUploadSuccess(true);
            setButtonAction("Validare Raspuns")
            }
            }
        } catch (error) {
          setMessage('An error occurred while generating response.');
          console.error(error);
        }
      };

  return (

    <div className="App">
    <header className="App-header">
     

{/*       <div className="logo-style">
        <img src={logo} className="App-logo" alt="logo" />
      </div> */}
      <div className="container_reg">
      <div className="message-container">
        <h2 className="title">Analiza Adresa Regularizare</h2>
        {jsonData2 && (
          <div className="pasi-reg">
            <h3>Sumar adresa regularizare:</h3>
            
            {/*<pre>{JSON.stringify(jsonData, null, 2)}</pre> */}
            {/* <p>{jsonData2}</p> */}
            <div>
                {jsonData2.map((line) => {
                    console.log(line.Step)
                return <p>{line.Step}</p>;
            })}
            </div>

            {/* <h3>Pasi:</h3>
            <div>
                {jsonData.map((pas) => {
                    console.log(pas.Step)
                return <p>{pas.Step}</p>;
            })}
            </div> */}

          </div>
        )}
      </div>
      {statusMessage && <p className="text-blue-600">{statusMessage}</p>}
      {message && <p className="text-red-600">{message}</p>}
      {showProgress && (
      <ProgressBar
        percent={progressBarPercent}
        filledBackground="linear-gradient(to right, #1565c0  ,green)"
      />
)}
      <br></br>
          <UploadButton text={buttonAction} onClick={generateResponse} loading={showLoader} disabled={showLoader} />
    </div>

    </header>
    </div>
  );
}

export default PasiRegularizare;