import logo from './enel-logo.png';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import FileUpload from './FileUpload';
import RegularizareUpload from './RegularizareUpload';
import PasiRegularizare from './PasiRegularizare';
import RegularizareResponse from './RegularizareResponse';
import SuccessPage from './SuccessPage'; // The success page component
import GenerateSomatie from './TemplateSomatie';
import ValidatePage from './ValidatePage';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import LoginPage from './LoginPage'

import { useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { b2cPolicies, protectedResources } from './authConfig';
import { compareIssuingPolicy } from './utils/claimUtils';

import { NavigationBar } from "./NavigationBar";



function App() {

  return (
    <div>
    <AuthenticatedTemplate>
      <NavigationBar />
    <Router>
      <Routes>
        <Route path="/" element={<FileUpload/>} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/validate" element={<ValidatePage />} />
        <Route path="/somatie" element={<GenerateSomatie />} />
        <Route path="/regularizare" element={<RegularizareUpload />} />
        <Route path="/pasireg" element={<PasiRegularizare />} />
        <Route path="/respreg" element={<RegularizareResponse />} />
      </Routes>
    </Router>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <LoginPage />
    </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
