// SuccessPage.js
import React, { useState } from 'react';

import logo from './enel-logo.png';
import './SuccessPage.css'; // Import the CSS file
import UploadButton from './UploadButton';
import './FileUpload.css';
import { ProgressBar } from "react-step-progress-bar";
import { useLocation } from 'react-router-dom';

function RegularizareResponse() {
    const location = useLocation();
    const jsonDataString = location.state ? location.state.jsonDataRegResp : null;

  // Parse the JSON data if available
    const jsonData = jsonDataString ? JSON.parse(jsonDataString) : null;
    console.log("jsonData success page",jsonData);

  return (

    <div className="App">
    <header className="App-header">
     

{/*       <div className="logo-style">
        <img src={logo} className="App-logo" alt="logo" />
      </div> */}
      <div className="container_reg_resp">
      <div className="message-container">
        <h2 className="title">Raspuns Adresa Regularizare</h2>
        {jsonData && (
          <div className="pasi-reg">
            {/*<pre>{JSON.stringify(jsonData, null, 2)}</pre> */}
            {/* <p>{jsonData}</p> */}
            <div>
                {jsonData.map((pas) => {
                    console.log(pas.Step)
                return <p>{pas.Step}</p>;
            })}
            </div>

          </div>
        )}
      </div>
    </div>

    </header>
    </div>
  );
}

export default RegularizareResponse;