//import React, { Component } from 'react';
import React from 'react';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

function GenerateSomatie(){
  //const queryParameters = new URLSearchParams(window.location.search)
  //const templateUrl = queryParameters.get("template")
  const generateDocument = () => {
    //console.log('Template URL:' + templateUrl);
      loadFile(
        'http://localhost:3000/Draft somatie EE_final.docx',
        function (error, content) {
          if (error) {
            throw error;
          }
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
/*           doc.setData({
            "cod_de_plata": "210084169",
            "data_emiterii": "21.05.2022",
            "id_factura": 55004701532,
            "scadenta_facturii": "06.06.2022",
            "serie_si_numar_factura": "22EI06107874",
            "tip_factura": "Estimare",
            "valoare": "14.409,39 lei",
            "valoare_restanta": "13.842,69 lei",
            "CNP_client": "1940706460065",
            "act_identitate_client": "Serie SZ Numar 339317 Emis de SPCLEP URZICENI La data 20.08.2012",
            "adresa_client": "Str. BARBULESTI Nr. 383 Bl. Sc. Ap. Localitate BARBULESTI Judet IALOMITA Cod postal 927031",
            "adresa_furnizor": "BUCURESTI, Sector 3, Bd. Mircea Voda, Nr.30, Et.6, Ap.CAM. 6.8",
            "cif_furnizor": "RO22000460",
            "client": "DRAGNEA FERDINAND",
            "data_contract": "22.03.2019",
            "nr_contract": "32691727",
            "numer_funizor": "ENEL ENERGIE S.A."
            }); */

            doc.setData({
              "invoice": {
                "cod_de_plata": "210084169",
                "data_emiterii": "21.05.2022",
                "id_factura": "55004701532",
                "scadenta_facturii": "06.06.2022",
                "serie_si_numar_factura": "22EI06107874",
                "tip_factura": "Estimare",
                "valoare": "14.409,39 lei",
                "valoare_restanta": "13.842,69 lei"
              },
              "contract": {
                "CNP_client": "1940706460065",
                "act_identitate_client": "Serie SZ Numar 339317 Emis de SPCLEP URZICENI La data 20.08.2012",
                "adresa_client": "Str. BARBULESTI Nr. 383 Bl. Sc. Ap. Localitate BARBULESTI Judet IALOMITA Cod postal 927031",
                "adresa_furnizor": "BUCURESTI, Sector 3, Bd. Mircea Voda, Nr.30, Et.6, Ap.CAM. 6.8",
                "cif_furnizor": "RO22000460",
                "client": "DRAGNEA FERDINAND",
                "data_contract": "22.03.2019",
                "nr_contract": "32691727",
                "numer_funizor": "ENEL ENERGIE S.A. Testare"
              }
            });
            
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join('\n');
              console.log('errorMessages', errorMessages);
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          var out = doc.getZip().generate({
            type: 'blob',
            mimeType:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }); //Output the document using Data-URI
          saveAs(out, 'output.docx');
        }
      );
    };

    return (
      <div className="p-2">
        <button onClick={generateDocument}>Generate document</button>
      </div>
    );
  }

  export default GenerateSomatie
