// SuccessPage.js
import React, { useState } from 'react';

import logo from './enel-logo.png';
import './SuccessPage.css'; // Import the CSS file
// import Mammoth from 'mammoth';
// import Docxtemplater from 'docxtemplater';
// import saveAs from 'file-saver';


import { useLocation } from 'react-router-dom';


function SuccessPage() {
    const location = useLocation();
    const jsonDataString = location.state ? location.state.jsonData : null;
    const [downloadLink, setDownloadLink] = useState(null);

  // Parse the JSON data if available
    const jsonData = jsonDataString ? JSON.parse(jsonDataString) : null;
    console.log("jsonData success page",jsonData);
    const docxFilePath = 'DraftsomatieEE.docx';

    // modifyDocx(jsonData, docxFilePath).then((modifiedDocxPath) => {
    //     if (modifiedDocxPath) {
    //       // Create a download link for the modified DOCX file
    //       const url = URL.createObjectURL(new Blob([modifiedDocxPath]));
    //       setDownloadLink(url);
    //     }
    //   });



      
      // Function to modify the DOCX file
    //   async function modifyDocx(jsonData, docxFilePath) {
    //     try {
    //       // Load the DOCX template
    //       const docxData = await fetch(docxFilePath).then((response) => response.arrayBuffer());
      
    //       // Create a new Docxtemplater instance
    //       const doc = new Docxtemplater();
    //       doc.loadZip(docxData);
      
    //       // Set the data for template replacement
    //       doc.setData(jsonData);
      
    //       // Render the document
    //       doc.render();
      
    //       // Get the modified DOCX as a Blob
    //       const modifiedBlob = doc.getZip().generate({ type: 'blob' });
      
    //       // Save the modified DOCX as a file
    //       saveAs(modifiedBlob, 'modified.docx');
    //     } catch (error) {
    //       console.error('Error modifying DOCX:', error);
    //     }
    //   }
      

  return (

    <div className="App">
    <header className="App-header">
     

{/*       <div className="logo-style">
        <img src={logo} className="App-logo" alt="logo" />
      </div> */}
      <div className="container">
      <div className="message-container">
        <h2 className="title">Success Page</h2>
        <p className="paragraphTitle">Your request was successful!</p>
        {jsonData && (
          <div className="json-data">
            <h3>JSON Data:</h3>
            <pre stype="text-wrap: initial;">{JSON.stringify(jsonData, null, 2)}</pre>
            
          </div>
        )}

    {downloadLink && (
          <div className="download-link">
            <a href={downloadLink} download="modified.docx">
              Download Modified DOCX
            </a>
          </div>
        )}
      </div>
    </div>

    </header>
    </div>
  );
}

export default SuccessPage;