// SuccessPage.js
import React, { useState } from 'react';

import logo from './enel-logo.png';
import './SuccessPage.css'; // Import the CSS file
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from "custom-error";
import { saveAs } from 'file-saver';
import { json, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import UploadButton from './UploadButton';

function ValidatePage() {
  const location = useLocation();
  const jsonDataString = location.state ? location.state.jsonData : null;
  const invoiceName = location.state ? location.state.invoiceName : null;
  const contractName = location.state ? location.state.contractName : null;
  const additional_files_names = location.state ? location.state.additional_files_names : null;
  const [downloadLink, setDownloadLink] = useState(null);
  const [downloadDC, setDownloadDC] = useState(null);
  const [downloadCVR, setDownloadCVR] = useState(null);
  const [somatieWiew, setsomatieWiew] = useState(false);
  const [dcView, setDcView] = useState(false);
  const [cvrView, setCvrView] = useState(false);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [somatieKey, setSomatieKey] = useState(0);
  const [cvrKey, setCvrKey] = useState(0);
  const [dcKey, setDcKey] = useState(0);
  const [mailRecipient, setMailRecipient] = useState('');
  const [mailStatus, setMailStatus] = useState('');
  const [blobSomatie, setBlobSomatie] = useState(null);
  const [blobCvr, setBlobCvr] = useState(null);
  const [blobDc, setBlobDc] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [mailValidation, setMailValidation] = useState('');

  var numer_funizor = null;
  var client = null;
  var adresa_client = null;
  var CNP_CIF_client = null;
  var valoare = null;
  var judecatorie = null;
  var nr_contract = null;
  var cod_de_plata = null;
  var id_factura = null;
  var adresa_furnizor = null;
  var dobanzi = null;
  var principal = null;
  var taxa_timbru = null;
  var tip_contract = null;
  var cnp_cif_label = null;
  var tip_cerere = null;
  var rata_dobanda = null;

  var templateSomatie = 'somatie_templ.docx';
  var templateDc = 'dc_templ.docx';
  var templateCvr = 'cvr_templ.docx';

  // Parse the JSON data if available
  const jsonData = jsonDataString ? JSON.parse(jsonDataString) : null;
  
  if (jsonData != null){

    tip_contract = jsonData.contract.tip_contract;
    tip_cerere = jsonData.invoice.tip_cerere;

    if(tip_contract == 1){
      cnp_cif_label = "CNP client:";
      client = jsonData.contract.client;
      CNP_CIF_client = jsonData.contract.CNP_client;
      templateSomatie = 'somatie_templ.docx';
      templateDc = 'dc_templ.docx';
      templateCvr = 'cvr_templ.docx';
    }else{
      cnp_cif_label = "CIF client:";
      client = jsonData.contract.denumire_firma;
      CNP_CIF_client = jsonData.contract.cif_firma;
      templateSomatie = 'somatie_templ_2.docx';
      templateDc = 'dc_templ_2.docx';
      templateCvr = 'cvr_templ_2.docx';
    }

    numer_funizor = jsonData.contract.numer_funizor;
    adresa_client = jsonData.invoice.adresa_client;
    valoare = jsonData.invoice.valoare;
    judecatorie = jsonData.invoice.judecatorie;
    nr_contract = jsonData.contract.nr_contract;
    cod_de_plata = jsonData.invoice.cod_de_plata;
    id_factura = jsonData.invoice.id_factura;
    adresa_furnizor = jsonData.contract.adresa_furnizor;
    dobanzi = jsonData.invoice.dobanzi;
    principal = jsonData.invoice.principal;
    taxa_timbru = jsonData.invoice.taxa_timbru;
    rata_dobanda = jsonData.contract.rata_dobanda;

  }

  function handleChange(event) {
    //console.log(event.target.name);
    //console.log(event.target.value);
    if(event.target.name == 'contract.numer_funizor'){
      jsonData.contract.numer_funizor = event.target.value;
    }
    if(event.target.name == 'contract.client'){
      jsonData.contract.client = event.target.value;
      jsonData.contract.denumire_firma = event.target.value;
    }
    if(event.target.name == 'contract.CNP_CIF_client'){
      jsonData.contract.CNP_client = event.target.value;
      jsonData.contract.cif_firma = event.target.value;
    }    
    if(event.target.name == 'invoice.adresa_client'){
      jsonData.invoice.adresa_client = event.target.value;
    }
    if(event.target.name == 'invoice.valoare'){
      jsonData.invoice.valoare = event.target.value;
    }
    if(event.target.name == 'invoice.judecatorie'){
      jsonData.invoice.judecatorie = event.target.value;
    }
    if(event.target.name == 'contract.nr_contract'){
      jsonData.contract.nr_contract = event.target.value;
    }
    if(event.target.name == 'invoice.cod_de_plata'){
      jsonData.invoice.cod_de_plata = event.target.value;
    }
    if(event.target.name == 'contract.adresa_furnizor'){
      jsonData.contract.adresa_furnizor = event.target.value;
    }
    if(event.target.name == 'invoice.dobanzi'){
      jsonData.invoice.dobanzi = event.target.value;
    }
    if(event.target.name == 'invoice.principal'){
      jsonData.invoice.principal = event.target.value;
    }
    if(event.target.name == 'invoice.taxa_timbru'){
      jsonData.invoice.taxa_timbru = event.target.value;
    }
    if(event.target.name == 'invoice.id_factura'){
      jsonData.invoice.id_factura = event.target.value;
    }
    if(event.target.name == 'contract.rata_dobanda'){
      jsonData.contract.rata_dobanda = event.target.value;
    }

    console.log("jsonData "+event.target.name+" changed", jsonData);
  }
  

  console.log("jsonData success page", jsonData);

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  if (downloadLink == null) { //to avoid aync function loop when re-render due to state change
    createDocx(jsonData, templateSomatie).then((modifiedDocxPath) => {
      if (modifiedDocxPath) {
        const docxUrl = URL.createObjectURL(modifiedDocxPath);
        setBlobSomatie(modifiedDocxPath)
        setDownloadLink(docxUrl);
        setSomatieKey((k) => k + 1);
      }
    });
  }
  if (downloadCVR == null && tip_cerere == 1) { //to avoid aync function loop when re-render due to state change
    createDocx(jsonData, templateCvr).then((modifiedDocxPath) => {
      if (modifiedDocxPath) {
        const docxUrl = URL.createObjectURL(modifiedDocxPath);
        setBlobCvr(modifiedDocxPath)
        setDownloadCVR(docxUrl);
        setCvrKey((k) => k + 1);
      }
    });
  }

  if (downloadDC == null) { //to avoid aync function loop when re-render due to state change
    createDocx(jsonData, templateDc).then((modifiedDocxPath) => {
      if (modifiedDocxPath) {
        const docxUrl = URL.createObjectURL(modifiedDocxPath);
        setBlobDc(modifiedDocxPath)
        setDownloadDC(docxUrl);
        setDcKey((k) => k + 1);
      }
    });
  }

  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };

  const handleSomatieView = () => {
    setsomatieWiew(!somatieWiew);
  };

  const handleDcView = () => {
    setDcView(!dcView);
  };

  const handleCvrView = () => {
    setCvrView(!cvrView);
  };

  const goHome = () => {
    console.log("Go Home");
    navigate('/');
  };

  const gotoRegularizare = async () => {
    try {

        navigate('/regularizare',{ state: { jsonDataContAndInv: jsonDataString } });

    } catch (error) {
      setMessage('An error occurred while moving to regularizare step.');
      console.error(error);
    }
  };

  const sendMail = async () => {
    try{
    const formSendMail = new FormData();
    console.log("sendMailTo>>", mailRecipient);

    setMailStatus("");

    if (mailRecipient == "") {
      setMailValidation('Introduceti adresa de E-mail');
      setMailStatus("");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mailRecipient)) {
      setMailValidation('Adresa de E-mail este invalida');
      setMailStatus("");
    } else {
    setShowLoader(true);
    formSendMail.append('email_recipient', mailRecipient);
    formSendMail.append('nume_client_dosar', client);

    if(blobSomatie !== null){
      formSendMail.append('email_docs', blobSomatie, "Somatie.docx")
    }

    if(blobCvr !== null){
      formSendMail.append('email_docs', blobCvr, "CVR.docx")
    }

    if(blobDc !== null){
      formSendMail.append('email_docs', blobDc, "DC.docx")
    }

    if(invoiceName !== null && invoiceName !== undefined){
      formSendMail.append('uploaded_file_names', invoiceName)
    }

    if(contractName !== null && contractName !== undefined){
      formSendMail.append('uploaded_file_names', contractName)
    }

    if(invoiceName !== null && invoiceName !== undefined){
      formSendMail.append('uploaded_file_names', invoiceName)
    }

    if(additional_files_names !== null && additional_files_names !== undefined){
      for (let i = 0 ; i < additional_files_names.length ; i++) {
        formSendMail.append('uploaded_file_names', additional_files_names[i]);
      }
    }

    //const responseMail = await fetch('http://localhost:81/mail_with_files', {
    const responseMail = await fetch('https://enelbackend6.azurewebsites.net/mail_with_files', {
      method: 'POST',
      body: formSendMail
    });

    if (responseMail.ok) {
      const jsonMailResponse = await responseMail.json();
      console.log("jsonMailResponse>>",jsonMailResponse);
      setMailValidation("");
      setMailStatus('E-mail trimis cu succes');
    } else {
      setMailValidation('E-mailul nu a fost trimis');
    }

    setShowLoader(false);
  }
  }catch (error) {
    setMailValidation('Mail Error');
      console.error("Error Sending mail", error);
      setShowLoader(false);
    }
  };

  const handleChaneRecipient = (event) => {
    setMailRecipient(event.target.value);
    if (event.target.value !== '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  function refreshAllDocx(){
    setDownloadLink(null);
    setDownloadCVR(null);
    setDownloadDC(null);
  }

  async function createDocx(jsonData, docxFilePath) {
    try {
      const docxData = await fetch(docxFilePath).then((response) => response.arrayBuffer());
      var zip = new PizZip(docxData);

      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.setData(jsonData);
      doc.render();

      const modifiedDocxPath = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      return modifiedDocxPath;

    } catch (error) {
      console.error("Error Creating Docx", error)
    }
  }

  function manualCorrectionSomatie(event) {
    event.preventDefault()
    navigate('/validate',{ state: { jsonData: JSON.stringify(jsonData), invoiceName: invoiceName, contractName: contractName, additional_files_names : additional_files_names } });
    refreshAllDocx();
  }

  function manualCorrectionDC(event) {
    event.preventDefault()
    navigate('/validate',{ state: { jsonData: JSON.stringify(jsonData), invoiceName: invoiceName, contractName: contractName, additional_files_names : additional_files_names } });
    refreshAllDocx();
  }

  function manualCorrectionCVR(event) {
    event.preventDefault()
    navigate('/validate',{ state: { jsonData: JSON.stringify(jsonData), invoiceName: invoiceName, contractName: contractName, additional_files_names : additional_files_names } });
    refreshAllDocx();
  }

  return (

    <div className="App">
      <header className="App-header">


{/*         <div className="logo-style" onClick={goHome}>
          <img src={logo} className="App-logo" alt="logo" />
        </div> */}
        <div className="container">
          <div className="message-container">
            <h2 className="title"></h2>
            <p className="paragraphTitle">Urmatoarele documente au fost generate cu succes</p>
            {/*         {jsonData && (
          <div className="json-data">
            <h3>JSON Data:</h3>
            <pre stype="text-wrap: initial;">{JSON.stringify(jsonData, null, 2)}</pre>
            
          </div>
        )} */}

            {/* <DocViewer documents={docs} /> */}
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>Somatie de plata</td>
                </tr>
                <tr>
                  <td>
                    <button className="upload-button" onClick={handleSomatieView}>Preview</button>
                  </td>
                  <td>
                    {downloadLink && (
                      <div className="download-link">
                        <button className="upload-button"><a href={downloadLink} download="Somatie.docx">
                          Download
                        </a></button>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* {downloadLink && ( */}
            {somatieWiew && (
              <table>
              <tbody>
                <tr>
                <td className='extracted_data'>
                <div className="upload-area">
                <form onSubmit={manualCorrectionSomatie}>
                <label>
                    Client:
                   <input type="text" name="contract.client" onChange={handleChange} defaultValue={client || undefined}/>
                  </label>
                  <label>
                    Adresa client:
                   <input type="text" name="invoice.adresa_client" onChange={handleChange} defaultValue={adresa_client || undefined}/>
                  </label>
                  <label>
                    Nr. Contract:
                   <input type="text" name="contract.nr_contract" onChange={handleChange} defaultValue={nr_contract || undefined}/>
                  </label>
                  <label>
                    Cod de plata:
                   <input type="text" name="invoice.cod_de_plata" onChange={handleChange} defaultValue={cod_de_plata || undefined}/>
                  </label>
                  <label>
                    Id Factura:
                   <input type="text" name="invoice.id_factura" onChange={handleChange} defaultValue={id_factura || undefined}/>
                  </label>
                  <label>
                    Furnizor:
                   <input type="text" name="contract.numer_funizor" onChange={handleChange} defaultValue={numer_funizor || undefined}/>
                  </label>
                  <label>
                    Total de plata:
                   <input type="text" name="invoice.valoare" onChange={handleChange} defaultValue={valoare || undefined}/>
                  </label>
                  <label>
                    Principal:
                   <input type="text" name="invoice.principal" onChange={handleChange} defaultValue={principal || undefined}/>
                  </label>
                  <label>
                    Dobanzi:
                   <input type="text" name="invoice.dobanzi" onChange={handleChange} defaultValue={dobanzi || undefined}/>
                  </label>
                  <label>
                    Taxa de timbru:
                   <input type="text" name="invoice.taxa_timbru" onChange={handleChange} defaultValue={taxa_timbru || undefined}/>
                  </label>
                  <input  type="submit" value="Update"/>
                  </form>
                  </div>
                
                </td>
                  <td>
              <div key={somatieKey}>           
              <FileViewer
                fileType={"docx"}
                filePath={downloadLink}
                errorComponent={CustomErrorComponent}
                onError={onError}/>
                </div>
                </td>
                </tr>
                </tbody>
                </table>
            )}

          {downloadCVR && (
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    Formular de cerere
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="upload-button" onClick={handleCvrView}>Preview</button>
                  </td>
                  <td>
                    {downloadCVR && (
                      <div className="download-link">
                        <button className="upload-button"><a href={downloadCVR} download="CVR.docx">
                          Download
                        </a></button>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            )}
            {/* {downloadDC && ( */}
            {cvrView && (
              <table>
              <tbody>
                <tr>
                <td className='extracted_data'>
                <div className="upload-area">
                <form onSubmit={manualCorrectionCVR}>
                <label>
                    Judecatorie:
                   <input type="text" name="invoice.judecatorie" onChange={handleChange} defaultValue={judecatorie || undefined}/>
                  </label>
                  <label>
                    Furnizor:
                   <input type="text" name="contract.numer_funizor" onChange={handleChange} defaultValue={numer_funizor || undefined}/>
                  </label>
                  <label>
                    Adresa furnizor:
                   <input type="text" name="contract.adresa_furnizor" onChange={handleChange} defaultValue={adresa_furnizor || undefined}/>
                  </label>
                  <label>
                    Client:
                   <input type="text" name="contract.client" onChange={handleChange} defaultValue={client || undefined}/>
                  </label>
                  <label>
                    Adresa client:
                   <input type="text" name="invoice.adresa_client" onChange={handleChange} defaultValue={adresa_client || undefined}/>
                  </label>
                  <label>
                    {cnp_cif_label}
                   <input type="text" name="contract.CNP_CIF_client" onChange={handleChange} defaultValue={CNP_CIF_client || undefined}/>
                  </label>
                  <label>
                    Id Factura:
                   <input type="text" name="invoice.id_factura" onChange={handleChange} defaultValue={id_factura || undefined}/>
                  </label>                  
                  <label>
                    Total de plata:
                   <input type="text" name="invoice.valoare" onChange={handleChange} defaultValue={valoare || undefined}/>
                  </label>
                  <label>
                    Principal:
                   <input type="text" name="invoice.principal" onChange={handleChange} defaultValue={principal || undefined}/>
                  </label>
                  <label>
                    Dobanzi:
                   <input type="text" name="invoice.dobanzi" onChange={handleChange} defaultValue={dobanzi || undefined}/>
                  </label>
                  <label>
                    Taxa de timbru:
                   <input type="text" name="invoice.taxa_timbru" onChange={handleChange} defaultValue={taxa_timbru || undefined}/>
                  </label>
                  <label>
                    Rata dobanda:
                   <input type="text" name="contract.rata_dobanda" onChange={handleChange} defaultValue={rata_dobanda || undefined}/>
                  </label>
                  <input  type="submit" value="Update"/>
                  </form>
                  </div>
                
                </td>
                  <td>
                  <div key={cvrKey}> 
              <FileViewer
                fileType={"docx"}
                filePath={downloadCVR}
                errorComponent={CustomErrorComponent}
                onError={onError} />
                </div>
                </td>
                </tr>
                </tbody>
                </table>
            )}

    {downloadDC && (
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}> Cerere de chemare in judecata</td>
                </tr>
                <tr>
                  <td>
                    <button className="upload-button" onClick={handleDcView}>Preview</button>
                  </td>
                  <td>
                    {downloadDC && (
                      <div className="download-link">
                        <button className="upload-button"><a href={downloadDC} download="DC.docx">
                          Download
                        </a></button>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            )}
            {/* {downloadDC && ( */}
            {dcView && (
              <table>
                <tbody>
                  <tr>
                  <td className='extracted_data'>
                  <div className="upload-area">
                  <form onSubmit={manualCorrectionDC}>
                    <label>
                      Furnizor:
                     <input type="text" name="contract.numer_funizor" onChange={handleChange} defaultValue={numer_funizor || undefined}/>
                    </label>
                    <label>
                      Client:
                     <input type="text" name="contract.client" onChange={handleChange} defaultValue={client || undefined}/>
                    </label>
                    <label>
                      Adresa client:
                     <input type="text" name="invoice.adresa_client" onChange={handleChange} defaultValue={adresa_client || undefined}/>
                    </label>
                    <label>
                      {cnp_cif_label}
                     <input type="text" name="contract.CNP_CIF_client" onChange={handleChange} defaultValue={CNP_CIF_client || undefined}/>
                    </label>
                    <label>
                    Id Factura:
                   <input type="text" name="invoice.id_factura" onChange={handleChange} defaultValue={id_factura || undefined}/>
                  </label>
                  <label>
                    Total de plata:
                   <input type="text" name="invoice.valoare" onChange={handleChange} defaultValue={valoare || undefined}/>
                  </label>
                  <label>
                    Principal:
                   <input type="text" name="invoice.principal" onChange={handleChange} defaultValue={principal || undefined}/>
                  </label>
                  <label>
                    Dobanzi:
                   <input type="text" name="invoice.dobanzi" onChange={handleChange} defaultValue={dobanzi || undefined}/>
                  </label>
                  <label>
                    Taxa de timbru:
                   <input type="text" name="invoice.taxa_timbru" onChange={handleChange} defaultValue={taxa_timbru || undefined}/>
                  </label>
                  <label>
                    Rata dobanda:
                   <input type="text" name="contract.rata_dobanda" onChange={handleChange} defaultValue={rata_dobanda || undefined}/>
                  </label>
                    <label>
                      Judecatorie:
                     <input type="text" name="invoice.judecatorie" onChange={handleChange} defaultValue={judecatorie || undefined}/>
                    </label>
                    <input  type="submit" value="Update"/>
                    </form>
                    </div>
                  
                  </td>
                    <td>
                    <div key={dcKey}> 
              <FileViewer
                fileType={"docx"}
                filePath={downloadDC}
                errorComponent={CustomErrorComponent}
                onError={onError} />
                </div>
                </td>
                </tr>
                </tbody>
                </table>
            )}
                <br></br>
                <div id="category-label">
                <label>Trimitere dosar pe E-mail</label>
                </div>

                <div className="upload-area">
                <div id="float-label">
                <input className="input-mail" type="text" name="email_address" onChange={handleChaneRecipient}/>
                <label className={ isActive ? "Active" : ""} htmlFor="email">
                E-mail
              </label>
              </div>
              <br></br>
                  {/* <button className="upload-button" onClick={sendMail}>Trimite Mail</button> */}
                  <UploadButton text="Trimite E-mail" onClick={sendMail} loading={showLoader} disabled={showLoader} />
                </div>
            <div className="mail-status">
            {mailValidation && <p className="text-blue-600">{mailValidation}</p>}
            {mailStatus && <p className="text-red-600">{mailStatus}</p>}
            </div>
          </div>
          <br></br>
          <UploadButton text="Regularizare" onClick={gotoRegularizare} loading={showLoader} disabled={showLoader} />
        </div>

      </header>
    </div>
  );
}

export default ValidatePage;