import React, { useState } from 'react';
import './FileUpload.css';
import logo from './enel-logo.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import UploadButton from './UploadButton';

import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";



function FileUpload() {
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [progressBarPercent, setProgressBarPercent] = useState(0)
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [buttonAction, setButtonAction] = useState("Generare Documente");
  const [jsonString, setJsonString] = useState("");

  const [additional_files_names, setAditional_files_names] = useState([]);
  var additional_files_tmp = [];

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };
  const handleFileChange2 = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles2(selectedFiles);
  };
  const handleFileChange3 = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles3(selectedFiles);
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleUploadInvoice = async () => {
    try {

      if(uploadSuccess){

        navigate('/validate',{ state: { jsonData: jsonString, invoiceName: files[0].name, contractName: files2[0].name, additional_files_names : additional_files_names} });

      }else if (files.length === 1 && files2.length === 1) {

        setShowLoader(true);
        setShowProgress(true);

        
        setStatusMessage("Upload Documente");
        await delay(2000);
        setProgressBarPercent(25);

        const formDataInvoice = new FormData();
        formDataInvoice.append('invoice_file', files[0]);

        const formDataContract = new FormData();
        formDataContract.append('contract_file', files2[0]);

        //Upload also additional files
        const formAditionalDocs = new FormData();
        //append each file in turn. FormData doesn't support multiple files
        for (let i = 0 ; i < files3.length ; i++) {
          formAditionalDocs.append("additional_files", files3[i]);
          additional_files_tmp[i] = files3[i].name;
          setAditional_files_names(additional_files_tmp);

        }
        console.log(files3);

        setStatusMessage("Extragere date din documentele incarcate");
        //const responseInvoice = await fetch('http://localhost:81/invoice_ai_service_by_file', {
        const responseInvoice = await fetch('https://enelbackend6.azurewebsites.net/invoice_ai_service_by_file', {
          method: 'POST',
          body: formDataInvoice
        });

        //setStatusMessage("Upload Contract");

        if (responseInvoice.ok) {
          const jsonDataInvoice = await responseInvoice.json();
          console.log("jsonData>>",jsonDataInvoice);

          setProgressBarPercent(50);
          setStatusMessage("Generare documente");
          //const responseContract = await fetch('http://localhost:81/contract_ai_service_by_file', {
          const responseContract = await fetch('https://enelbackend6.azurewebsites.net/contract_ai_service_by_file', {
            method: 'POST',
            body: formDataContract
          });

          setProgressBarPercent(75);
          setStatusMessage("Incarcare documente generate");
          await delay(2000);
  
          if (responseContract.ok) {

            const jsonDataContract = await responseContract.json();
            console.log("jsonDataContract>>",jsonDataContract);

            var jsonAdditionalFiles = null;

            if(files3.length > 0) {
              ///upload optional additional files, if fail I will still continue
              setProgressBarPercent(90);
              setStatusMessage("Incarcare Documente Aditionale");
              //const responseAdditionalFiles = await fetch('http://localhost:81/upload_additional_files', {
              const responseAdditionalFiles = await fetch('https://enelbackend6.azurewebsites.net/upload_additional_files', {
                method: 'POST',
                body: formAditionalDocs
              });

              jsonAdditionalFiles = await responseAdditionalFiles.json();
              console.log("jsonAdditionalFiles>>",jsonAdditionalFiles);
          }
            const jsonComplete = 
            {"invoice":jsonDataInvoice,
            "contract":jsonDataContract,
            "extraDocs": jsonAdditionalFiles};

            setJsonString(JSON.stringify(jsonComplete));

            setStatusMessage('');
            //setMessage('Files uploaded successfully.');
            setMessage('Documentele au fost generate.');

            setProgressBarPercent(100);
            

          setUploadSuccess(true);
          setButtonAction("Validare Documente")
          //navigate('/success',{ state: { jsonData: JSON.stringify(jsonComplete) } }); 
          //navigate('/validate',{ state: { jsonData: JSON.stringify(jsonComplete) } }); 

          }else{
            setMessage('File contract upload failed.');
          }

          
          
        } else {
          setMessage('File invoice upload failed.');
        }

        setShowLoader(false)

      } else {
        setMessage('Please select 2 files.');
      }
    } catch (error) {
      setMessage('An error occurred while uploading files.');
      console.error(error);
    }
  };


  return (


    <div className="App">
      <header className="App-header">
       

{/*         <div className="logo-style">
          <img src={logo} className="App-logo" alt="logo" />
        </div> */}


        <div className="container">

<h1 className="title">Documente Necesare:</h1>
<div className="upload-area">
  <label htmlFor="file-input">Ultima Factura Client</label>
  <input
    type="file"
    id="file-input"
    
    accept=".jpg, .png, .pdf, .doc, .docx"
    onChange={handleFileChange}
  />
</div>
<div className="upload-area">
  <label htmlFor="file-input2">Contract</label>
 
  <input
  
    type="file"
    id="file-input2"
    
    accept=".jpg, .png, .pdf, .doc, .docx"
    onChange={handleFileChange2}
  />
</div>
<div className="upload-area">
  <label htmlFor="file-input3">Documente Aditionale</label>
 
  <input
  
    type="file"
    id="file-input3"
    
    accept=".jpg, .png, .pdf, .doc, .docx"
    onChange={handleFileChange3}
    multiple
  />
</div>
{files.length > 0 && (
  <div className="selected-files">
    <h2 className="title">Ultima factura emisa a fost incarcata:</h2>
    <ul>
      {files.map((file, index) => (
        <li key={index}>{file.name}</li>
      ))}
    </ul>
  </div>
)}
{files2.length > 0 && (
  <div className="selected-files">
    <h2 className="title">Contractul a fost incarcat:</h2>
    <ul>
      {files2.map((file2, index) => (
        <li key={index}>{file2.name}</li>
      ))}
    </ul>
  </div>
)}
{files3.length > 0 && (
  <div className="selected-files">
    <h2 className="title">Urmatoarele documente aditionale au fost incarcate:</h2>
    <ul>
      {files3.map((file3, index) => (
        <li key={index}>{file3.name}</li>
      ))}
    </ul>
  </div>
)}
{statusMessage && <p className="text-blue-600">{statusMessage}</p>}
{message && <p className="text-red-600">{message}</p>}
{showProgress && (
      <ProgressBar
        percent={progressBarPercent}
        filledBackground="linear-gradient(to right, #1565c0  ,green)"
      />
)}
{/* <button className="upload-button" onClick={handleUploadInvoice}>
  Generare Documente
</button> */}
<br></br>
<UploadButton text={buttonAction} onClick={handleUploadInvoice} loading={showLoader} disabled={showLoader} />
</div>

       
      </header>
    

    </div>
  );
}

export default FileUpload;
