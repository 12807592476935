import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser"; 
import { loginRequest, b2cPolicies } from './authConfig';
import UploadButton from './UploadButton';

export const NavigationBar = () => {


    const { instance, inProgress } = useMsal();
     let activeAccount;

     if (instance) {
         activeAccount = instance.getActiveAccount();
     }

     const userName = instance.getActiveAccount() ? instance.getActiveAccount().name : '';

    const handleLoginPopup = () => {
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/redirect',
            })
            .catch((error) => console.log(error));
    };

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
        });
    };

    const handleProfileEdit = () => {
        if(inProgress === InteractionStatus.None){
           instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
        }
    };
    
    return (
        <>
    <nav className="navbar">
      <div className="nav-container">
      <div className="nav-elements">
        <ul>
          <li><div id='user-name'>{userName}</div></li>
          <li><button id='logout-button' onClick={handleLogoutRedirect}>Sign Out</button></li>
        </ul>
      </div>
      </div>
    </nav>
        </>
    );
};
