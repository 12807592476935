import { ReactComponent as Loader } from './loader.svg'

const UploadButton = ({ onClick, text, loading, disabled }) => {
    return (
      <button className="upload-button" onClick={onClick} disabled={disabled}>
        {!loading ? text : <Loader className="spinner" />}
      </button>
    )
  }
  
  export default UploadButton

  